<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资源管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/question' }">题库</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <div class="padding-b-sm">
                <el-form :model="form" ref="form1">
                    <el-form-item label="题库名称" prop="name"
                        :rules="[{ required: true, message: '请输入题库名称' , trigger: 'blur'}]">
                        <el-input style="width: 18.75rem;" v-model="form.name" placeholder="题库名称"></el-input>
                        <el-button class="margin-l-sm" size="mini" type="primary" @click="addTab">插入题目</el-button>
                        <el-button class="margin-l-sm" size="mini" type="danger" @click="removeTab">移除题目</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-tabs v-model="currentTab" type="card" @tab-remove="removeTab" @tab-click="selectTab">
                    <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                        <div>
                            <el-form :model="form.questions[currentTab]" label-width="100px" ref="form"
                                class="demo-ruleForm">
                                <el-form-item label="题目类型：" prop="type">
                                    <el-select size="mini" v-model="form.questions[currentTab].type" placeholder="请选择"
                                        @change="changeType(form.questions[currentTab].type)">
                                        <el-option v-for="item in questionType" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="选项类型：" prop="textType">
                                    <el-select size="mini" v-model="form.questions[currentTab].text_type"
                                        placeholder="请选择" :disabled="flag"
                                        @change="checkTypeItem(form.questions[currentTab].text_type)">
                                        <el-option v-for="item in textType" :key="item.value" :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="题目：" prop="content" v-if="form.questions[currentTab].type !== 5"
                                    :rules="[{ required: true, message: '请输入题目', trigger: 'blur' }]">
                                    <mini-editor @onChange="onChangeQuestion"
                                        :content="form.questions[currentTab].content" v-if="currentTab === item.name">
                                    </mini-editor>
                                </el-form-item>
                                <el-form-item label="题目：" prop="content"
                                    v-if="form.questions[currentTab].type == 5 && item.name == currentTab"
                                    :rules="[{ required: true, message: '请输入题目', trigger: 'blur' }]">
                                    <mindmap v-model="datas[currentTab]" height="700" :download="false"
                                        @updateNodeName="updateNodeName" @deleteAnswer="deleteAnswer"></mindmap>
                                </el-form-item>


                                <el-form-item :label="item.id+'答案:'" prop="content"
                                    v-for="item in mindmapAnswer[currentTab]" :key="item.id"
                                    v-show="form.questions[currentTab].type == 5">

                                    <el-input placeholder="请输入内容" v-for="(items,index) in item.items" :key="index"
                                        v-model="items.vla"></el-input>
                                </el-form-item>

                                <div v-show="form.questions[currentTab].type !== 3">
                                    <blockquote v-if="form.questions[currentTab].text_type === 1">
                                        <el-form-item label="答案：" prop="items"
                                            v-if="form.questions[currentTab].type === 4">
                                            <div v-for="(opt,index) in form.questions[currentTab].items" :key="index">
                                                <span v-if="form.questions[currentTab].type <= 2">{{indexToTxt[index]}}
                                                </span>
                                                <!-- <el-input class="input" v-model="opt.item" placeholder="输入答案"></el-input> -->
                                                <mini-editor @onChange="onChangeItem($event,index)" :content="opt.item"
                                                    v-if="currentTab === item.name"></mini-editor>
                                                <el-button class="margin-l-sm" size="mini"
                                                    @click.prevent="removeOption(index)">删除</el-button>
                                            </div>
                                            <el-button size="mini" type="primary" @click="addOption">添加答案</el-button>
                                        </el-form-item>
                                        <el-form-item label="选项：" prop="items"
                                            v-if="form.questions[currentTab].type === 1 || form.questions[currentTab].type === 2 ">
                                            <div v-for="(opt,index) in form.questions[currentTab].items" :key="index">
                                                <span v-if="form.questions[currentTab].type <= 2">{{indexToTxt[index]}}
                                                </span>
                                                <mini-editor @onChange="onChangeItem($event,index)" :content="opt.item"
                                                    v-if="currentTab === item.name"></mini-editor>
                                                <el-button class="margin-l-sm" size="mini"
                                                    @click.prevent="removeOption(index)">删除</el-button>
                                            </div>
                                            <el-button size="mini" type="primary" @click="addOption">添加选项</el-button>
                                        </el-form-item>
                                    </blockquote>
                                    <el-form-item label="选项：" label-position="left" prop="items"
                                        v-else-if="form.questions[currentTab].text_type === 2">
                                        <paste-image upload-path="question" @onSuccess="onSuccess" @onRemove="onRemove">
                                        </paste-image>
                                    </el-form-item>
                                </div>


                                <el-form-item label="正确答案：" label-position="left"
                                    v-if="form.questions[currentTab].type !== 5">
                                    <el-radio-group v-if="form.questions[currentTab].type === 1"
                                        v-model="form.questions[currentTab].answer.answer[0]">

                                        <div class="fl-l">
                                            <el-radio :label="item.item"
                                                v-for="(item,index) in form.questions[currentTab].items" :key="index">
                                                <div>{{indexToTxt[index]}} &nbsp;</div>
                                                <div v-if="form.questions[currentTab].text_type === 1"
                                                    v-html="item.item"></div>
                                                <div style="width: 100px;height: 100px"
                                                    v-if="form.questions[currentTab].text_type === 2">
                                                    <el-image style="width: 100%; height: 100%"
                                                        :src=" imageUrl + item.item"
                                                        :preview-src-list="[imageUrl + item.item]">
                                                    </el-image>
                                                </div>
                                            </el-radio>
                                        </div>

                                    </el-radio-group>

                                    <el-checkbox-group v-if="form.questions[currentTab].type === 2"
                                        v-model="form.questions[currentTab].answer.answer">
                                        <el-checkbox :label="item.item"
                                            v-for="(item,index) in form.questions[currentTab].items" :key="index">
                                            <div>{{indexToTxt[index]}} &nbsp;</div>
                                            <div v-if="form.questions[currentTab].text_type === 1" v-html="item.item">
                                            </div>
                                            <div style="width: 100px;height: 100px"
                                                v-if="form.questions[currentTab].text_type === 2">
                                                <el-image style="width: 100%; height: 100%" :src=" imageUrl + item.item"
                                                    :preview-src-list="[imageUrl + item.item]">
                                                </el-image>
                                            </div>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                    <el-radio-group v-if="form.questions[item.name].type === 3"
                                        v-model="form.questions[currentTab].answer.answer[0]">
                                        <el-radio :label="item.item"
                                            v-for="(item,index) in form.questions[currentTab].items" :key="index">
                                            <span>{{item.item}} </span>
                                        </el-radio>
                                    </el-radio-group>

                                    <div v-if="form.questions[currentTab].type === 4">
                                        <span v-for="(item,index) in form.questions[currentTab].items" :key="index">
                                            <p style="display: inline-block;margin-left: 10px;" v-html="item.item"></p>
                                        </span>
                                    </div>
                                </el-form-item>

                                <el-form-item label="题目解析：" prop="analyses.content"
                                    :rules="[{ required: true, message: '请输入解析', trigger: 'blur' }]">
                                    <mini-editor @onChange="onChangeAnalyses"
                                        :content="form.questions[currentTab].analyses.content"
                                        v-if="currentTab === item.name"></mini-editor>
                                </el-form-item>
                            </el-form>

                        </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import mindmap from '@hellowuxin/mindmap'
import { mapActions } from "vuex"
import PasteImage from "../../../components/pasteImage/index"
import miniEditor from '../../../components/questionEditor/index'
export default {
    name: "edit",
    components: { PasteImage, miniEditor, mindmap },
    watch: {
        'form.questions': {
            deep: true,
            handler: function (newVal, oldVal) {
                if (this.form.questions[this.currentTab].type != 5) {
                    this.form.questions[this.currentTab].answer.answer = newVal[this.currentTab].answer.answer
                }
            }
        }
    },
    data() {
        return {
            datas: [],
            mindmapAnswer: [],
            imageUrl: config.imageUrl,
            indexToTxt: {
                '0': 'A',
                '1': 'B',
                '2': 'C',
                '3': 'D',
                '4': 'E',
                '5': 'F',
                '6': 'G',
                '7': 'H',
                '8': 'I',
                '9': 'J',
            },
            uploadPath: 'question',
            uploadNumber: 6,
            currentTab: '0',
            textType: [
                {
                    value: 1,
                    label: '文本'
                },
                {
                    value: 2,
                    label: '图片'
                }
            ],
            questionType: [
                {
                    value: 1,
                    label: '单选题'
                },
                {
                    value: 2,
                    label: '多选题'
                },
                {
                    value: 3,
                    label: '判断题'
                },
                {
                    value: 4,
                    label: '填空题'
                },
                {
                    value: 5,
                    label: '思维导图'
                },
            ],
            editableTabs: [],
            form: {},
            // 控制选项是否可选
            flag: false
        }
    },
    methods: {
        ...mapActions('questionBank', ['addQuestionBank', 'getFormatEditData']),
        updateNodeName(e, id) {
            let i = this.currentTab
            let num = e.name.indexOf('？')
            let items = []
            items.length = e.name.split('？').length - 1
            items.forEach(v => v == '')
            for (let index = 0; index < items.length; index++) {
                items[index] = {
                    key: Date.now()
                }
            }
            if (num == -1) return
            let index = this.mindmapAnswer[i].findIndex(v => v.id == id)
            if (index == -1) {
                this.mindmapAnswer[i].push({
                    id: id,
                    items,
                })
            } else {
                this.mindmapAnswer[i][index].items = items
            }
        },
        //添加选项
        removeOption(index) {
            this.form.questions[this.currentTab].items.splice(index, 1)
        },
        //删除答案
        deleteAnswer(e, id) {
            let i = this.currentTab
            let index = null
            this.mindmapAnswer[i].forEach((v, i) => {
                if (v.id == id) {
                    index = i
                }
            })
            this.mindmapAnswer[i].splice(i, 1)
        },
        //添加选项
        addOption() {
            this.form.questions[this.currentTab].items.push({
                item: '',
            })
        },
        // 表单提交前
        validateForm() {
            debugger
            this.$refs['form1'].validate(async valid => {
                if (!valid) {
                    return
                }
            })
            if (this.form.questions[this.currentTab].type === 5) {
                for (let index = 0; index < this.form.questions.length; index++) {
                    const element = this.form.questions[index];
                    if (element.type == 5) {
                        this.form.questions[index].content = JSON.stringify(this.datas[index])
                    }
                }
            }
            this.$refs.form[this.currentTab].validate(async valid => {
                if (!valid) {
                    return
                }
                this.submit()
            })
        },
        //提交表单保存
        async submit() {
            let form = JSON.parse(JSON.stringify(this.form))
            form.questions.forEach((item, index) => {
                if (item.type == 5) {
                    form.questions[index].answer.answer = this.mindmapAnswer[index]
                } else if (item.type == 4) {
                    let a = []
                    item.items.forEach(v => {
                        a.push(v.item)
                    })
                    form.questions[index].rightAnswer = a
                    form.questions[index].answer.answer = item.rightAnswer
                }
            })

            const { res_info, data } = await this.addQuestionBank(form)
            if (res_info !== 'ok') {
                this.$message.error('保存失败!')
                return
            }
            this.$message.success('保存成功!')
            this.$router.push('/question')
        },
        selectTab(tab, event) {
            this.currentTab = tab.index
        },
        //添加题目
        addTab() {
            let index = this.editableTabs.length
            let newTabName = index
            this.editableTabs.push({
                title: '题目' + (newTabName + 1),
                name: newTabName + '',
            });
            this.form.questions.push({
                content: '',
                type: 1,
                text_type: 1,
                items: [],
                answer: { answer: [] },
                analyses: { content: '' }
            })
            this.currentTab = newTabName + ''
            this.currentIndex = Number(this.currentTab)

            this.datas.push([{ "name": "如何学习D1" }])
            this.mindmapAnswer.push([])
        },
        //删除题目
        removeTab() {
            if (this.editableTabs.length === 1) {
                this.$message.error('至少保存一道题目')
                return
            }
            let tabs = this.editableTabs;
            let activeName = (this.editableTabs.length - 1) + '';
            var vm = this
            vm.form.questions.splice(activeName, 1)
            vm.currentTab = '0'
            vm.editableTabs = tabs.filter(tab => tab.name !== activeName);
        },
        // 图片上传
        onSuccess(path) {
            var pics = path + ''
            var that = this
            var pathList = pics.split(',')
            var relPath = pathList[pathList.length - 1]
            that.form.questions[that.currentTab].items.push({ item: relPath })
        },
        // 图片移除
        onRemove(index, file) {
            this.form.questions[this.currentTab].items.splice(index, 1)
        },
        //修改选项类型
        checkTypeItem(v) {
            this.form.questions[this.currentTab].items = []
            this.form.questions[this.currentTab].answer.answer = []
        },
        changeType(val) {
            if (val === 4 || val === 3) {
                this.form.questions[this.currentTab].text_type = 1
                this.flag = true
            } else {
                this.flag = false
            }
            this.form.questions[this.currentTab].items = []
            if (val === 3) {
                this.form.questions[this.currentTab].items = [
                    { item: '正确' },
                    { item: '错误' }
                ]
            }
            this.form.questions[this.currentTab].answer.answer = []
        },
        //选择答案
        selectAnswer(e) {

        },
        // 更改题目
        onChangeQuestion(content) {
            this.form.questions[this.currentTab].content = content
        },
        // 更改选项
        onChangeItem(content, index) {
            this.form.questions[this.currentTab].items[index].item = content
        },
        // 更改解析
        onChangeAnalyses(content) {
            this.form.questions[this.currentTab].analyses.content = content
        }
    },
    // 挂载
    async mounted() {
        var that = this
        var id = that.$route.query.id
        const { res_info, data } = await that.getFormatEditData({ id: id })
        if (res_info !== 'ok') return
        that.form = data
        data.questions.forEach(v => {
            let content
            let answer
            if (v.type == 5) {
                content = JSON.parse(v.content)
                answer = v.answer.answer
                console.log(v.answer.answer);
            } else {
                content = [{ "name": "如何学习D1" }]
                answer = []
            }
            that.datas.push(content)
            that.mindmapAnswer.push(answer)

        })
        var totalTab = String(that.form.questions.length - 1 ?? 0)
        that.currentTab = totalTab
        that.form.questions.forEach(function (item, index) {
            that.editableTabs[index] = {
                'title': '题目' + (index + 1),
                'name': '' + index
            }
        })
    }
}
</script>
<style>
.el-radio,
.el-checkbox {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.el-radio,
.el-radio__label,
.el-checkbox,
.el-checkbox__label {
    display: flex;
    align-items: center;
}
</style>
